<template>
  <div class="ma-10">
    <v-row>
      <v-col cols="12">
        <form-step-title title="Compléments (spécifique ESR)" />
      </v-col>
    </v-row>
    <v-form v-if="type_organisation.nom === 'Établissement ESR'">
      <v-row>
        <v-col cols="12">
          <p class="text-h2">
            Votre établissement {{ entite.nom }} dépend du regroupement d'établissements {{ esr.nom }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-multi-select 
            v-model="entite_ids"
            field-label="Liste des entités dépendants de l'ESR"
            label="Liste des entités"
            :multiple="true"
            item-text="nom"
            item-value="id"
            class="mt-1"
            :items="entites"
            @change="saveEsr"
          />
          <form-fields-textarea
            v-model="commentaire"
            field-label="Commentaires" 
            placeholder="Commentaires"
            type="textarea"
            class="mt-5"
            @change="saveEsr"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row v-else>
      <v-col>
        <div class="text-h2">
          Vous n'êtes pas concerné par cette section, vous pouvez passer directement à la suivante.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsMultiSelect from '@/components/FormFieldsMultiSelect.vue'
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import { mapState, mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: "SpecificEsr",
  components: { 
    FormStepTitle,
    FormFieldsMultiSelect,
    FormFieldsTextarea,
  },
  computed: {
    ...mapState('form', ['entites', 'esr', 'entite']),
    ...mapFields('form', [
      'esr.entite_ids',
      'esr.commentaire',
      'entite.type_organisation',
    ]),
  },
  created() {
    this.fetchEntites();
    this.fetchEntite();
    this.fetchEsr();
    this.setNextView('/profile/intern-ratios');

  },
  methods: {
    ...mapActions('form', ['fetchEntites', 'saveEsr', 'fetchEsr', 'fetchEntite']),
    ...mapMutations('stepper', ['setNextView'])
  }
}
</script>